<template>
    <div class="password-recovery">
        <div class="container">
            <div class="d-flex justify-content-center">
                <img src="@/assets/demex-logo-big.png" class="responsive-img" />
            </div>
            <div class="d-flex justify-content-center">
                <b-card class="mb-2" :disabled="isDisabled">
                    <b-card-title class="d-flex justify-content-between">
                        Nytt lösenord <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading"></b-icon>
                    </b-card-title>
                    <b-card-text>
                        <label for="newPassword">Lösenord:</label>
                        <b-form-input type="password" id="newPassword" v-model="newPassword"></b-form-input>
                        <label for="confirmNewPassword" class="mt-2">Bekräfta lösenord:</label>
                        <b-form-input type="password" id="confirmNewPassword" v-model="confirmNewPassword"></b-form-input>
                        <div class="mt-3 text-danger"><small>{{ message }}</small></div>
                    </b-card-text>

                    <template #footer>
                        <div class="responsive-footer">
                            <b-button variant="success" @click="changePasswordClicked()" :disabled="isLoading">Byt lösenord</b-button>
<!--                             <b-button variant="primary" class="mt-2 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0" @click="switchToLogin()">Logga in &nbsp;&nbsp;<b-icon icon="arrow-right-circle" aria-hidden="true"></b-icon></b-button>
 -->                        </div>
                    </template>
                </b-card>
            </div>
        </div>
    </div>
</template>
 
<style lang="css" scoped>
.responsive-img {
  width: 100%;
  height: auto;
  max-width: 250px;
  margin-bottom: 60px;
}
    .password-recovery {
        margin: -30px;
        padding-top: 60px;
        height: 103vh;
        background: #003440;
    }

    @media (min-width: 768px) {

/* For desktop: */
.card {
    min-width: 600px;
}
.responsive-footer{
    display: flex;
    justify-content: flex-end;
}
}
@media (max-width: 768px) {
/* For mobile: */
.card {
    min-width: 300px;
}
.responsive-footer{
    display: grid;
}
}

    .card-body {
        background: #f1f0e6;
    }
</style>       

<script>
    import accountService from '@/services/accountService';
    export default {
        name: "passwordRecovery",
        data: () => ({
            isDisabled: false,
            isLoading: false,
            valid: false,
            isUnauthorized: false,
            token: "",
            email: "",
            guid: "",
            disablePasswordChanging: true,
            message: '',
            // form values
            newPassword: '',
            confirmNewPassword: '',
        }),
        methods: {
            changePasswordClicked() {
                accountService.passwordRecoveryChangePassword({
                    token: this.token, email: this.email, guid: this.guid, newPassword: this.newPassword, confirmNewPassword: this.confirmNewPassword
                }).then(data => {
                    this.disablePasswordChanging = data.disablePasswordChanging;
                    this.message = data.message;
                });
            },
            passwordRecoveryValidate() {
                accountService.passwordRecoveryValidate({
                    token: this.token, email: this.email, guid: this.guid
                }).then(data => {
                    this.disablePasswordChanging = data.disablePasswordChanging;
                    this.message = data.message;
                });
            },
            switchToLogin() {
                this.$router.push({ path: 'login' })
            }
        },
        mounted() {
            this.token = this.$route.query.token
            this.email = this.$route.query.email
            this.guid = this.$route.query.guid
            this.passwordRecoveryValidate()
        }
    }
</script>